.activity-card {
    width: 300px;
    height: 300px;
    padding: 5px;
    overflow: hidden;
    position: relative;
} 

.activity-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

 .activity-card:hover .activity-image {
    transform: scale(1.2);
    animation-play-state: paused;
} 

/* Scrolling animation styles */
.partners-container {
    display: flex;
    overflow: hidden;
    width: 100%;
     
}

.partners {
    display: flex;
    animation: scrollPartners 70s linear infinite;
    
}

@keyframes scrollPartners { 
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
} 
    
.paused .partners {
    animation-play-state: paused;
}











